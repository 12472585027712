/**
 * @param totalValue
 * @param totalPrincipal
 * @param totalDividends
 * @param cashHeld
 */
export function calculateTotalReturns(
  totalValue: number,
  totalPrincipal: number,
  totalDividends: number,
  cashHeld = 0
) {
  const totalValueWithOrWithoutCash = totalValue + cashHeld
  const totalPrincipalWithOrWithoutCash = totalPrincipal + cashHeld

  return (
    totalValueWithOrWithoutCash -
    totalPrincipalWithOrWithoutCash +
    totalDividends
  )
}
