/**
 * @param totalReturns
 * @param totalPrincipal
 * @param cashHeld
 */
export function calculateTotalReturnsPercentage(
  totalReturns: number,
  totalPrincipal: number,
  cashHeld = 0
) {
  const totalPrincipalWithOrWithoutCash = totalPrincipal + cashHeld

  if (totalReturns === 0 || totalPrincipalWithOrWithoutCash === 0) {
    return 0
  }

  return (totalReturns / totalPrincipalWithOrWithoutCash) * 100
}
